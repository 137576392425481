
.text-dark {
  color: #2f2e41;
}

.bg-dark {
  background-color: #2f2e41;
}

.scrollable {
  overflow: scroll !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.styles_tooltip__1ZMky img {
  margin: auto !important;
  height: 70% !important;
}

/* change text selection color  */

*::selection {
    background: #FBBF24;
}
*::-moz-selection {
  background: #FBBF24;
}
*::-webkit-selection {
    background: #FBBF24;
}

canvas.react-pdf__Page__canvas {
  max-width: 50vw !important;
  height: auto !important;
  width: auto !important;
  max-height: 100vh !important;
}

.not_selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

input, textarea { 
    -webkit-user-select: text !important; /* Chrome, Opera, Safari */
    -moz-user-select: text !important; /* Firefox 2+ */
    -ms-user-select: text !important; /* IE 10+ */
    user-select: text !important; /* Standard syntax */
}

.Toastify__progress-bar--default {
  color: #FBBF24 !important;
  background: #FBBF24 !important;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  border: none !important;
  outline: none !important;
}

.video-chat {
  float: right !important;
  max-width: 50vw !important;
  min-width: 50vw !important;
  width: 50vw !important;
}

.reader {
  float: left;
  width: 50vw !important;
}

.Toastify__progress-bar--default {
  color: #FBBF24 !important;
  background: #FBBF24 !important;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  border: none !important;
  outline: none !important;
}

.text-force-gray {
  color: rgba(75, 85, 99, 1) !important;
}

/* .ct-body {
top: auto  !important;;
bottom: auto  !important;;
left: auto  !important;;
right: auto !important;;

}

#remote-vid-wrapper {
  top: 0 !important;;
bottom: 0 !important;;
left: 50% !important;;
right: 0 !important;;

}

#catalyst .ct-multi-btn {
  left: 75% !important;
}

#catalyst #ct-header p, img {
padding: 0 !important;
float:inherit !important;
} */

